const dataLabels: any = {
  riwayat_penyakit: "Riwayat Penyakit",
  asal_sekolah: "Asal Sekolah",
  "Data Keluarga": "Data Keluarga",
  user: "Data User",
  role: "Role",
  potongan_gaji: "Data Potongan",
  nik: "Nomor Induk Karyawan",
  email: "Email",
  no_rm: "No. Rekam Medis",
  no_sip: "No. SIP",
  no_manulife: "No. Manulife",
  tgl_masuk: "Tanggal Masuk",
  unit_kerja: "Unit Kerja",
  jabatan: "Jabatan",
  kompetensi: "Kompetensi",
  nik_ktp: "Nomor Induk Keluarga (NIK KTP)",
  status_karyawan: "Status Karyawan",
  tempat_lahir: "Tempat Lahir",
  tgl_lahir: "Tanggal Lahir",
  kelompok_gaji: "Kelompok Gaji",
  no_rekening: "No. Rekening",
  tunjangan_jabatan: "Tunjangan Jabatan",
  tunjangan_fungsional: "Tunjangan Fungsional",
  tunjangan_khusus: "Tunjangan Khusus",
  tunjangan_lainnya: "Tunjangan Lainnya",
  uang_lembur: "Uang Lembur",
  uang_makan: "Uang Makan",
  ptkp: "PTKP",
  tgl_keluar: "Tanggal Keluar",
  no_kk: "No. Kartu Keluarga",
  alamat: "Alamat",
  gelar_depan: "Gelar Depan",
  gelar_belakang: "Gelar Belakang",
  no_hp: "No. Telepon",
  no_bpjsksh: "No. BPJS Kesehatan",
  no_bpjsktk: "No. BPJS Ketenagakerjaan",
  tgl_diangkat: "Tanggal Diangkat",
  masa_kerja: "Masa Kerja",
  npwp: "NPWP",
  jenis_kelamin: "Jenis Kelamin",
  agama: "Agama",
  golongan_darah: "Golongan Darah",
  pendidikan_terakhir: "Pendidikan Terakhir",
  tinggi_badan: "Tinggi Badan",
  berat_badan: "Berat Badan",
  no_ijazah: "No Ijazah",
  tahun_lulus: "Tahun Lulus",
  no_str: "No. STR",
  masa_berlaku_str: "Masa Berlaku STR",
  tgl_berakhir_pks: "Tanggal Berakhir PKS",
  masa_diklat: "Masa Diklat",
  file_sip: "File SIP",
  file_ktp: "File KTP",
  file_kk: "File Kartu Keluarga",
  file_bpjs_kesehatan: "File BPJS Kesehatan",
  file_bpjs_ketenagakerjaan: "File BPJS Ketenagakerjaan",
  file_ijazah: "File Ijazah",
  file_sertifikat: "File STR",
};

export default dataLabels;
