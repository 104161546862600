export default function formatBytes(bytes: number) {
  if (bytes) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return Math.ceil(bytes / Math.pow(k, i)) + " " + sizes[i];
  }
  return "";
}
