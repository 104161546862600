import { Interface__ValidStatusKerja } from "./interfaces";

const dummyActivity = [
  {
    type: "Masuk",
    timestamp: "Wed Apr 17 2024 09:06:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Keluar",
    timestamp: "Tue Apr 16 2024 17:19:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Masuk",
    timestamp: "Tue Apr 16 2024 08:58:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Keluar",
    timestamp: "Mon Apr 15 2024 17:12:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Masuk",
    timestamp: "Mon Apr 15 2024 09:03:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Keluar",
    timestamp: "Mon Apr 15 2024 17:12:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Masuk",
    timestamp: "Mon Apr 15 2024 09:03:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Keluar",
    timestamp: "Mon Apr 15 2024 17:12:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Masuk",
    timestamp: "Mon Apr 15 2024 09:03:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Keluar",
    timestamp: "Mon Apr 15 2024 17:12:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Masuk",
    timestamp: "Mon Apr 15 2024 09:03:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Keluar",
    timestamp: "Mon Apr 15 2024 17:12:00 GMT+0700 (Indochina Time)",
  },
  {
    type: "Masuk",
    timestamp: "Mon Apr 15 2024 09:03:00 GMT+0700 (Indochina Time)",
  },
];

const dummyCutis = [
  {
    kategori: {
      id: 1,
      label: "Tahunan",
    },
    tanggal_from: "06-24-2024",
    tanggal_to: "06-28-2024",
    durasi: 3,
    status_cuti: true,
  },
  {
    kategori: {
      id: 3,
      label: "Pribadi",
    },
    tanggal_from: "06-24-2024",
    tanggal_to: "06-28-2024",
    durasi: 3,
    status_cuti: true,
  },
  {
    kategori: {
      id: 3,
      label: "Pribadi",
    },
    tanggal_from: "06-24-2024",
    tanggal_to: "06-28-2024",
    durasi: 3,
    status_cuti: true,
  },
  {
    kategori: {
      id: 1,
      label: "Tahunan",
    },
    tanggal_from: "06-24-2024",
    tanggal_to: "06-28-2024",
    durasi: 3,
    status_cuti: false,
  },
];

const dummyLemburs = [
  {
    id: 1,
    user_id: 101,
    shift_id: 1,
    tgl_pengajuan: "2025-07-03",
    kompensasi: 200000,
    tipe: "Lembur Rutin",
    durasi: 3600,
    catatan: "Lembur proyek tambahan",
    status_lembur: "Disetujui",
    created_at: "2024-07-03 09:00:00",
    updated_at: "2024-07-03 15:30:00",
  },
  {
    id: 2,
    user_id: 102,
    shift_id: 2,
    tgl_pengajuan: "2024-07-02",
    kompensasi: 150000,
    tipe: "Lembur Akhir Pekan",
    durasi: 7600,
    catatan: "Lembur untuk menyelesaikan deadline",
    status_lembur: "Ditolak",
    created_at: "2024-07-02 10:15:00",
    updated_at: "2024-07-02 16:45:00",
  },
  {
    id: 3,
    user_id: 103,
    shift_id: 1,
    tgl_pengajuan: "2024-07-01",
    kompensasi: 180000,
    tipe: "Lembur Malam",
    durasi: 7600,
    catatan: "Lembur karena keadaan darurat",
    status_lembur: "Menunggu Persetujuan",
    created_at: "2024-07-01 08:30:00",
    updated_at: "2024-07-01 14:00:00",
  },
];

const dummyPengajuanTukarJadwals = [
  {
    id: 1,
    jenis_penukaran: { id: 1, label: "Tukar Shift" },
    user_ditukar: {
      id: 101,
      nama: "User A",
      username: "usera",
      email_verified_at: "2024-07-01T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 201,
      nama: "Shift Pagi",
      jam_from: "2024-07-05T08:00:00Z",
      jam_to: "2024-07-05T16:00:00Z",
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    user_pengajuan: {
      id: 1,
      nama: "User B",
      username: "userb",
      email_verified_at: "2024-07-01T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 202,
      nama: "Shift Malam",
      jam_from: "2024-07-05T16:00:00Z",
      jam_to: "2024-07-06T00:00:00Z",
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    status_penukaran: null,
    created_at: "2024-07-01T00:00:00Z",
    updated_at: "2024-07-01T00:00:00Z",
  },
  {
    id: 2,
    jenis_penukaran: { id: 1, label: "Tukar Shift" },
    user_ditukar: {
      id: 103,
      nama: "User C",
      username: "userc",
      email_verified_at: "2024-07-02T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 203,
      nama: "Shift Pagi",
      jam_from: "2024-07-06T08:00:00Z",
      jam_to: "2024-07-06T16:00:00Z",
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    user_pengajuan: {
      id: 1,
      nama: "User D",
      username: "userd",
      email_verified_at: "2024-07-02T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 204,
      nama: "Shift Malam",
      jam_from: "2024-07-06T16:00:00Z",
      jam_to: "2024-07-07T00:00:00Z",
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    status_penukaran: false,
    created_at: "2024-07-02T00:00:00Z",
    updated_at: "2024-07-02T00:00:00Z",
  },
  {
    id: 3,
    jenis_penukaran: { id: 1, label: "Tukar Shift" },
    user_ditukar: {
      id: 105,
      nama: "User E",
      username: "usere",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 205,
      nama: "Shift Siang",
      jam_from: "2024-07-07T12:00:00Z",
      jam_to: "2024-07-07T20:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    user_pengajuan: {
      id: 1,
      nama: "User F",
      username: "userf",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 206,
      nama: "Shift Pagi",
      jam_from: "2024-07-07T08:00:00Z",
      jam_to: "2024-07-07T16:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    status_penukaran: true,
    created_at: "2024-07-03T00:00:00Z",
    updated_at: "2024-07-03T00:00:00Z",
  },
  {
    id: 4,
    jenis_penukaran: { id: 1, label: "Tukar Shift" },
    user_ditukar: {
      id: 105,
      nama: "User E",
      username: "usere",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 205,
      nama: "Shift Siang",
      jam_from: "2024-07-07T12:00:00Z",
      jam_to: "2024-07-07T20:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    user_pengajuan: {
      id: 1,
      nama: "User F",
      username: "userf",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 206,
      nama: "Shift Pagi",
      jam_from: "2024-07-07T08:00:00Z",
      jam_to: "2024-07-07T16:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    status_penukaran: true,
    created_at: "2024-07-03T00:00:00Z",
    updated_at: "2024-07-03T00:00:00Z",
  },
  {
    id: 5,
    jenis_penukaran: { id: 1, label: "Tukar Shift" },
    user_ditukar: {
      id: 105,
      nama: "User E",
      username: "usere",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 205,
      nama: "Shift Siang",
      jam_from: "2024-07-07T12:00:00Z",
      jam_to: "2024-07-07T20:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    user_pengajuan: {
      id: 1,
      nama: "User F",
      username: "userf",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 206,
      nama: "Shift Pagi",
      jam_from: "2024-07-07T08:00:00Z",
      jam_to: "2024-07-07T16:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    status_penukaran: true,
    created_at: "2024-07-03T00:00:00Z",
    updated_at: "2024-07-03T00:00:00Z",
  },
];

const dummyPermintaanTukarJadwals = [
  {
    id: 6,
    jenis_penukaran: { id: 2, label: "Tukar Libur" },
    user_ditukar: {
      id: 1,
      nama: "User A",
      username: "usera",
      email_verified_at: "2024-07-01T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 201,
      nama: "Shift Pagi",
      jam_from: "2024-07-05T08:00:00Z",
      jam_to: "2024-07-05T16:00:00Z",
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    user_pengajuan: {
      id: 102,
      nama: "User B",
      username: "userb",
      email_verified_at: "2024-07-01T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 202,
      nama: "Shift Malam",
      jam_from: "2024-07-05T16:00:00Z",
      jam_to: "2024-07-06T00:00:00Z",
      created_at: "2024-07-01T00:00:00Z",
      updated_at: "2024-07-01T00:00:00Z",
    },
    status_penukaran: true,
    created_at: "2024-07-01T00:00:00Z",
    updated_at: "2024-07-01T00:00:00Z",
  },
  {
    id: 7,
    jenis_penukaran: { id: 2, label: "Tukar Libur" },
    user_ditukar: {
      id: 1,
      nama: "User C",
      username: "userc",
      email_verified_at: "2024-07-02T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 203,
      nama: "Shift Pagi",
      jam_from: "2024-07-06T08:00:00Z",
      jam_to: "2024-07-06T16:00:00Z",
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    user_pengajuan: {
      id: 104,
      nama: "User D",
      username: "userd",
      email_verified_at: "2024-07-02T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 204,
      nama: "Shift Malam",
      jam_from: "2024-07-06T16:00:00Z",
      jam_to: "2024-07-07T00:00:00Z",
      created_at: "2024-07-02T00:00:00Z",
      updated_at: "2024-07-02T00:00:00Z",
    },
    status_penukaran: false,
    created_at: "2024-07-02T00:00:00Z",
    updated_at: "2024-07-02T00:00:00Z",
  },
  {
    id: 8,
    jenis_penukaran: { id: 2, label: "Tukar Libur" },
    user_ditukar: {
      id: 1,
      nama: "User E",
      username: "usere",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 205,
      nama: "Shift Siang",
      jam_from: "2024-07-07T12:00:00Z",
      jam_to: "2024-07-07T20:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    user_pengajuan: {
      id: 106,
      nama: "User F",
      username: "userf",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 206,
      nama: "Shift Pagi",
      jam_from: "2024-07-07T08:00:00Z",
      jam_to: "2024-07-07T16:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    status_penukaran: null,
    created_at: "2024-07-03T00:00:00Z",
    updated_at: "2024-07-03T00:00:00Z",
  },
  {
    id: 9,
    jenis_penukaran: { id: 2, label: "Tukar Libur" },
    user_ditukar: {
      id: 1,
      nama: "User E",
      username: "usere",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 1,
      foto_profil: null,
      data_completion_step: 2,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_ditukar: {
      id: 205,
      nama: "Shift Siang",
      jam_from: "2024-07-07T12:00:00Z",
      jam_to: "2024-07-07T20:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    user_pengajuan: {
      id: 106,
      nama: "User F",
      username: "userf",
      email_verified_at: "2024-07-03T00:00:00Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    jadwal_pengajuan: {
      id: 206,
      nama: "Shift Pagi",
      jam_from: "2024-07-07T08:00:00Z",
      jam_to: "2024-07-07T16:00:00Z",
      created_at: "2024-07-03T00:00:00Z",
      updated_at: "2024-07-03T00:00:00Z",
    },
    status_penukaran: true,
    created_at: "2024-07-03T00:00:00Z",
    updated_at: "2024-07-03T00:00:00Z",
  },
];

const dummyKaryawans: any = [
  {
    id: 1,
    user: {
      id: 2,
      nama: "User 0",
      username: "username0",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:42.000000Z",
      updated_at: "2024-06-07T06:57:42.000000Z",
    },
    nik: "2415692",
    no_rm: 1828094,
    unit_kerja: {
      id: 20,
      nama_unit: "Psikiatri",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2024-03-19T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Kontrak",
    status_kerja: "Kerja" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:42.000000Z",
    updated_at: "2024-06-07T06:57:42.000000Z",
  },
  {
    id: 2,
    user: {
      id: 3,
      nama: "User 1",
      username: "username1",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:42.000000Z",
      updated_at: "2024-06-07T06:57:42.000000Z",
    },
    nik: "1528863",
    no_rm: 4600727,
    unit_kerja: {
      id: 16,
      nama_unit: "Fisioterapi",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2023-07-29T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Magang",
    status_kerja: "Cuti" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 5,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:42.000000Z",
    updated_at: "2024-06-07T06:57:42.000000Z",
  },
  {
    id: 3,
    user: {
      id: 4,
      nama: "User 2",
      username: "username2",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:43.000000Z",
      updated_at: "2024-06-07T06:57:43.000000Z",
    },
    nik: "2371077",
    no_rm: 4327005,
    unit_kerja: {
      id: 11,
      nama_unit: "Kebidanan dan Kandungan",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2023-06-18T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Tetap",
    status_kerja: "Libur" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 5,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:43.000000Z",
    updated_at: "2024-06-07T06:57:43.000000Z",
  },
  {
    id: 4,
    user: {
      id: 5,
      nama: "User 3",
      username: "username3",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:43.000000Z",
      updated_at: "2024-06-07T06:57:43.000000Z",
    },
    nik: "271683",
    no_rm: 355076,
    unit_kerja: {
      id: 6,
      nama_unit: "Pemeliharaan Sarana Prasarana",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2023-06-13T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Magang",
    status_kerja: "Libur" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:43.000000Z",
    updated_at: "2024-06-07T06:57:43.000000Z",
  },
  {
    id: 5,
    user: {
      id: 6,
      nama: "User 4",
      username: "username4",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:43.000000Z",
      updated_at: "2024-06-07T06:57:43.000000Z",
    },
    nik: "2331089",
    no_rm: 103065,
    unit_kerja: {
      id: 1,
      nama_unit: "Asuransi Kesehatan",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2024-05-20T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Kontrak",
    status_kerja: "Kerja" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 5,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:43.000000Z",
    updated_at: "2024-06-07T06:57:43.000000Z",
  },
  {
    id: 6,
    user: {
      id: 7,
      nama: "User 5",
      username: "username5",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:44.000000Z",
      updated_at: "2024-06-07T06:57:44.000000Z",
    },
    nik: "2128093",
    no_rm: 1076505,
    unit_kerja: {
      id: 14,
      nama_unit: "Apotek",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2023-06-26T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Kontrak",
    status_kerja: "Kerja" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 5,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:44.000000Z",
    updated_at: "2024-06-07T06:57:44.000000Z",
  },
  {
    id: 7,
    user: {
      id: 8,
      nama: "User 6",
      username: "username6",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:44.000000Z",
      updated_at: "2024-06-07T06:57:44.000000Z",
    },
    nik: "2246555",
    no_rm: 3574313,
    unit_kerja: {
      id: 13,
      nama_unit: "Onkologi",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2024-01-17T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Tetap",
    status_kerja: "Cuti" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:44.000000Z",
    updated_at: "2024-06-07T06:57:44.000000Z",
  },
  {
    id: 8,
    user: {
      id: 9,
      nama: "User 7",
      username: "username7",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:44.000000Z",
      updated_at: "2024-06-07T06:57:44.000000Z",
    },
    nik: "2220567",
    no_rm: 4660600,
    unit_kerja: {
      id: 20,
      nama_unit: "Psikiatri",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2024-03-19T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Magang",
    status_kerja: "Kerja" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:44.000000Z",
    updated_at: "2024-06-07T06:57:44.000000Z",
  },
  {
    id: 9,
    user: {
      id: 10,
      nama: "User 8",
      username: "username8",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:45.000000Z",
      updated_at: "2024-06-07T06:57:45.000000Z",
    },
    nik: "578774",
    no_rm: 1187264,
    unit_kerja: {
      id: 3,
      nama_unit: "Laboratorium",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2024-02-08T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Tetap",
    status_kerja: "Kerja" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 5,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:45.000000Z",
    updated_at: "2024-06-07T06:57:45.000000Z",
  },
  {
    id: 10,
    user: {
      id: 11,
      nama: "User 9",
      username: "username9",
      email_verified_at: null,
      role_id: null,
      foto_profil: null,
      data_completion_step: 1,
      status_aktif: 0,
      created_at: "2024-06-07T06:57:45.000000Z",
      updated_at: "2024-06-07T06:57:45.000000Z",
    },
    nik: "3181412",
    no_rm: 943415,
    unit_kerja: {
      id: 24,
      nama_unit: "Gizi",
      jenis_karyawan: {
        id: 1,
        label: "Shift",
      },
      created_at: "2024-05-26T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
    },
    status_karyawan: "Magang",
    status_kerja: "Kerja" as Interface__ValidStatusKerja,
    jadwals: [
      {
        id: 1,
        nama: "Pagi",
        jam_from: "2024-01-01 06:00:00",
        jam_to: "2024-01-01 15:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 2,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 3,
        nama: "Malam",
        jam_from: "2024-01-01 23:10:00",
        jam_to: "2024-01-02 06:10:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
      {
        id: 4,
        nama: "Sore",
        jam_from: "2024-01-01 15:10:00",
        jam_to: "2024-01-01 23:00:00",
        created_at: "2024-05-24T03:42:50.000000Z",
        updated_at: "2024-05-24T03:42:50.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:45.000000Z",
    updated_at: "2024-06-07T06:57:45.000000Z",
  },
];

const dummyDetailKaryawan: any = {
  id: 2,
  user: {
    id: 3,
    nama: "User 1",
    username: "username1",
    email_verified_at: null,
    role_id: null,
    foto_profil: "/images/gear5.jpg",
    data_completion_step: 1,
    status_aktif: 0,
    created_at: "2024-06-07T06:57:42.000000Z",
    updated_at: "2024-06-07T06:57:42.000000Z",
    roles: [
      {
        id: 4,
        name: "Karyawan",
        deskripsi:
          "recently cream related duty negative spring struck carbon saddle labor damage return court tide blue tea complex foot zoo broken clean been complete conversation",
        guard_name: "web",
        created_at: "2024-02-08T06:57:41.000000Z",
        updated_at: "2024-06-07T06:57:41.000000Z",
        pivot: {
          model_type: "App\\Models\\User",
          model_id: 3,
          role_id: 4,
        },
      },
    ],
  },
  email: "user1@example.com",
  no_rm: 4600727,
  no_manulife: 4665476,
  tgl_masuk: "2020-02-02",
  unit_kerja: {
    id: 16,
    nama_unit: "Fisioterapi",
    jenis_karyawan: {
      id: 1,
      label: "Shift",
    },
    created_at: "2023-07-29T06:57:41.000000Z",
    updated_at: "2024-06-07T06:57:41.000000Z",
  },
  jabatan: {
    id: 18,
    nama_jabatan: "Tenaga Kebersihan",
    is_struktural: 0,
    tunjangan: 3915229,
    created_at: "2024-02-06T06:57:41.000000Z",
    updated_at: "2024-06-07T06:57:41.000000Z",
  },
  kompetensi: {
    id: 6,
    nama_kompetensi: "Tenaga Farmasi",
    jenis_kompetensi: 0,
    total_tunjangan: 1046338,
    created_at: "2023-10-03T06:57:41.000000Z",
    updated_at: "2024-06-07T06:57:41.000000Z",
  },
  role: [
    {
      id: 4,
      name: "Karyawan",
      deskripsi:
        "recently cream related duty negative spring struck carbon saddle labor damage return court tide blue tea complex foot zoo broken clean been complete conversation",
      guard_name: "web",
      created_at: "2024-02-08T06:57:41.000000Z",
      updated_at: "2024-06-07T06:57:41.000000Z",
      pivot: {
        model_type: "App\\Models\\User",
        model_id: 3,
        role_id: 4,
      },
    },
  ],
  nik: "1528863",
  nik_ktp: "401721",
  status_karyawan: "Magang",
  tempat_lahir: "Kediri",
  tgl_lahir: "1926-07-21",
  kelompok_gaji: {
    id: 10,
    nama_kelompok: "H",
    besaran_gaji: 2930866,
    created_at: "2023-10-06T06:57:41.000000Z",
    updated_at: "2024-06-07T06:57:41.000000Z",
  },
  no_rekening: "4934012",
  tunjangan_jabatan: 6762158,
  tunjangan_fungsional: 2398237,
  tunjangan_khusus: 2450809,
  tunjangan_lainnya: 1766551,
  uang_lembur: 1149807,
  uang_makan: 989290,
  ptkp: {
    id: 8,
    kode_ptkp: "K/3",
    kategori_ter_id: 3,
    created_at: "2024-06-07T06:57:41.000000Z",
    updated_at: "2024-06-07T06:57:41.000000Z",
  },
  tgl_keluar: "2024-06-25",
  no_kk: "53453910",
  alamat:
    "missing impossible coach amount welcome here night trail diameter nervous graph outline shinning perfectly try refer classroom climb burn spider grabbed waste little provide",
  gelar_depan: "Adv.",
  no_hp: "161926352",
  no_bpjsksh: "209422264",
  no_bpjsktk: "445164924",
  tgl_diangkat: "2024-06-25",
  masa_kerja: 30,
  npwp: "263438379",
  jenis_kelamin: {
    id: 1,
    label: "Laki-Laki",
  },
  agama: {
    id: 1,
    label: "Islam",
  },
  golongan_darah: {
    id: 1,
    label: "A",
  },
  tinggi_badan: 284,
  berat_badan: 56,
  no_ijazah: "IJ/VII/153907485",
  tahun_lulus: 2024,
  no_str: "STR/01/RA/329735",
  masa_berlaku_str: "2025-02-26",
  no_sip: "291292",
  masa_berlaku_sip: "2025-02-26",
  tgl_berakhir_pks: "2024-06-25",
  masa_diklat: 10,
  created_at: "2024-06-07T06:57:42.000000Z",
  updated_at: "2024-06-07T06:57:42.000000Z",
};

const dummyDataBerkas = {
  ktp: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  kk: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  sip: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  bpjsksh: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  bpjsktk: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  ijazah: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  sertifikat_kompetensi: {
    id: 1,
    name: "gear5.jpg",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
};

const dummyDokumens = [
  {
    id: 1,
    nama: "Word Example",
    name: "Word Example.docx",
    size: 12344,
    type: "application/docx",
    path: "/file/word.docx",
    created_at: "2024-07-12",
  },
  {
    id: 2,
    nama: "PDF Example",
    name: "PDF Example.pdf",
    size: 12344,
    type: "application/pdf",
    path: "/file/pdf.pdf",
    created_at: "2024-07-12",
  },
  {
    id: 3,
    nama: "Excel Example",
    name: "Excel Example.xlsx",
    size: 12344,
    type: "image/jpg",
    path: "/file/excel.xlsx",
    created_at: "2024-07-12",
  },
  {
    id: 4,
    nama: "CSV Example",
    name: "CSV Example.csv",
    size: 12344,
    type: "image/jpg",
    path: "/file/csv.csv",
    created_at: "2024-07-12",
  },
  {
    id: 5,
    nama: "Very very long name yhat posible to be",
    name: "Very very long name yhat posible to be.pdf",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  {
    id: 6,
    nama: "Very very long name yhat posible to be",
    name: "Very very long name yhat posible to be.pdf",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
  {
    id: 7,
    nama: "Very very long name yhat posible to be",
    name: "Very very long name yhat posible to be.pdf",
    size: 12344,
    type: "image/jpg",
    path: "/images/gear5.jpg",
    created_at: "2024-07-12",
  },
];

const dummyMySchedules = [
  {
    id: 1,
    nama: "Pagi",
    jam_from: "2024-01-01 06:00:00",
    jam_to: "2024-01-01 15:00:00",
    assignees: dummyKaryawans,
    created_at: "2024-05-24T03:42:50.000000Z",
    updated_at: "2024-05-24T03:42:50.000000Z",
  },
  {
    id: 2,
    nama: "Sore",
    jam_from: "2024-01-01 15:10:00",
    jam_to: "2024-01-01 23:00:00",
    assignees: dummyKaryawans,
    created_at: "2024-05-24T03:42:50.000000Z",
    updated_at: "2024-05-24T03:42:50.000000Z",
  },
  {
    id: 3,
    nama: "Malam",
    jam_from: "2024-01-01 23:10:00",
    jam_to: "2024-01-02 06:10:00",
    assignees: dummyKaryawans,
    created_at: "2024-05-24T03:42:50.000000Z",
    updated_at: "2024-05-24T03:42:50.000000Z",
  },
  {
    id: 4,
    nama: "Libur",
    jam_from: "2024-01-01 23:10:00",
    jam_to: null,
    assignees: dummyKaryawans,
    created_at: "2024-05-24T03:42:50.000000Z",
    updated_at: "2024-05-24T03:42:50.000000Z",
  },
  {
    id: 5,
    nama: "Sore",
    jam_from: "2024-01-01 15:10:00",
    jam_to: "2024-01-01 23:00:00",
    assignees: dummyKaryawans,
    created_at: "2024-05-24T03:42:50.000000Z",
    updated_at: "2024-05-24T03:42:50.000000Z",
  },
];

const dummyEventDiklats: any = [
  {
    id: 1,
    gambar: "/images/diklat.jpg",
    nama: "Diklat Psikologi, Mengetahui Dampak Bermain Game terhadap Kecerdasan Anak Usia di Bawah 5 Tahun",
    jenis: "Workshop",
    tanggal: "2024-07-01",
    tempat: "Auditorium A",
    waktu: "2024-07-01T09:00:00.000Z",
    penanggung_jawab: {
      id: 3,
      nama: "Dr. John Doe",
      username: "johndoe",
      email_verified_at: "2024-06-07T06:57:42.000000Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-05-15T06:57:42.000000Z",
      updated_at: "2024-06-07T06:57:42.000000Z",
    },
    peserta: [
      {
        id: 1,
        user: {
          id: 2,
          nama: "User 0",
          username: "username0",
          email_verified_at: null,
          role_id: null,
          foto_profil: null,
          data_completion_step: 1,
          status_aktif: 0,
          created_at: "2024-06-07T06:57:42.000000Z",
          updated_at: "2024-06-07T06:57:42.000000Z",
        },
        nik: "2415692",
        no_rm: 1828094,
        unit_kerja: {
          id: 20,
          nama_unit: "Psikiatri",
          jenis_karyawan: {
            id: 1,
            label: "Shift",
          },
          created_at: "2024-03-19T06:57:41.000000Z",
          updated_at: "2024-06-07T06:57:41.000000Z",
        },
        status_karyawan: "Kontrak",
        status_kerja: "Kerja",
        jadwals: [
          {
            id: 1,
            nama: "Pagi",
            jam_from: "2024-01-01 06:00:00",
            jam_to: "2024-01-01 15:00:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
          {
            id: 2,
            nama: "Sore",
            jam_from: "2024-01-01 15:10:00",
            jam_to: "2024-01-01 23:00:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
          {
            id: 3,
            nama: "Malam",
            jam_from: "2024-01-01 23:10:00",
            jam_to: "2024-01-02 06:10:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
          {
            id: 4,
            nama: "Sore",
            jam_from: "2024-01-01 15:10:00",
            jam_to: "2024-01-01 23:00:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
        ],
        created_at: "2024-06-07T06:57:42.000000Z",
        updated_at: "2024-06-07T06:57:42.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:42.000000Z",
    updated_at: "2024-06-07T06:57:42.000000Z",
  },
  {
    id: 3,
    gambar: null,
    nama: "Diklat Keperawatan",
    jenis: "Seminar",
    tanggal: "2024-08-01",
    tempat: "Aula B",
    waktu: "2024-08-01T09:00:00.000Z",
    penanggung_jawab: {
      id: 3,
      nama: "Dr. John Doe",
      username: "johndoe",
      email_verified_at: "2024-06-07T06:57:42.000000Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-05-15T06:57:42.000000Z",
      updated_at: "2024-06-07T06:57:42.000000Z",
    },
    peserta: null,
    created_at: "2024-06-07T06:57:42.000000Z",
    updated_at: "2024-06-07T06:57:42.000000Z",
  },
  {
    id: 1,
    gambar: "/images/diklat.jpg",
    nama: "Diklat Psikologi, Mengetahui Dampak Bermain Game terhadap Kecerdasan Anak Usia di Bawah 5 Tahun",
    jenis: "Workshop",
    tanggal: "2024-07-01",
    tempat: "Auditorium A",
    waktu: "2024-07-01T09:00:00.000Z",
    penanggung_jawab: {
      id: 3,
      nama: "Dr. John Doe",
      username: "johndoe",
      email_verified_at: "2024-06-07T06:57:42.000000Z",
      role_id: 2,
      foto_profil: null,
      data_completion_step: 3,
      status_aktif: 1,
      created_at: "2024-05-15T06:57:42.000000Z",
      updated_at: "2024-06-07T06:57:42.000000Z",
    },
    peserta: [
      {
        id: 1,
        user: {
          id: 2,
          nama: "User 0",
          username: "username0",
          email_verified_at: null,
          role_id: null,
          foto_profil: null,
          data_completion_step: 1,
          status_aktif: 0,
          created_at: "2024-06-07T06:57:42.000000Z",
          updated_at: "2024-06-07T06:57:42.000000Z",
        },
        nik: "2415692",
        no_rm: 1828094,
        unit_kerja: {
          id: 20,
          nama_unit: "Psikiatri",
          jenis_karyawan: {
            id: 1,
            label: "Shift",
          },
          created_at: "2024-03-19T06:57:41.000000Z",
          updated_at: "2024-06-07T06:57:41.000000Z",
        },
        status_karyawan: "Kontrak",
        status_kerja: "Kerja",
        jadwals: [
          {
            id: 1,
            nama: "Pagi",
            jam_from: "2024-01-01 06:00:00",
            jam_to: "2024-01-01 15:00:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
          {
            id: 2,
            nama: "Sore",
            jam_from: "2024-01-01 15:10:00",
            jam_to: "2024-01-01 23:00:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
          {
            id: 3,
            nama: "Malam",
            jam_from: "2024-01-01 23:10:00",
            jam_to: "2024-01-02 06:10:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
          {
            id: 4,
            nama: "Sore",
            jam_from: "2024-01-01 15:10:00",
            jam_to: "2024-01-01 23:00:00",
            created_at: "2024-05-24T03:42:50.000000Z",
            updated_at: "2024-05-24T03:42:50.000000Z",
          },
        ],
        created_at: "2024-06-07T06:57:42.000000Z",
        updated_at: "2024-06-07T06:57:42.000000Z",
      },
    ],
    created_at: "2024-06-07T06:57:42.000000Z",
    updated_at: "2024-06-07T06:57:42.000000Z",
  },
];

const dummySlipGaji = {
  take_home_pay: 6500000,
  penghasilan: {
    total: 7200000,
    rincian: [
      {
        nama: "Gaji Pokok",
        besaran: 5000000,
      },
      {
        nama: "Tunjangan",
        besaran: 200000,
      },
      {
        nama: "Tunjangan Jabatan",
        besaran: 2000000,
      },
    ],
  },
  potongan: {
    total: 700000,
    rincian: [
      {
        nama: "BPJS Kesehatan",
        besaran: 1000000,
      },
      {
        nama: "JHT Employee",
        besaran: 200000,
      },
      {
        nama: "PPh23",
        besaran: 400000,
      },
    ],
  },
  ringkasan_kehadiran: {
    hari_kerja: 31,
    jadwal_hari_kerja: 31,
    libur: 8,
    libur_nasional: 0,
    hari_libur_khusus: 0,
    kehadiran: 24,
  },
};

const dummyTipeCutis = [
  {
    id: 1,
    nama: "Cuti Tahunan",
    terpakai: 4,
    kuota: 12, // kuota pertahun
    is_need_requirement: false,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
  {
    id: 2,
    nama: "Cuti di Luar Tanggungan",
    terpakai: 5,
    kuota: 30, // kuota pertahun
    is_need_requirement: true,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
  {
    id: 3,
    nama: "Cuti Besar",
    terpakai: 7,
    kuota: 12, // kuota pertahun
    is_need_requirement: true,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
  {
    id: 4,
    nama: "Cuti Kelahiran",
    terpakai: 0,
    kuota: 90, // kuota pertahun
    is_need_requirement: true,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
  {
    id: 5,
    nama: "Cuti Sakit",
    terpakai: 2,
    kuota: null, // kuota pertahun
    is_need_requirement: true,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
  {
    id: 6,
    nama: "Cuti Nikah",
    terpakai: 0,
    kuota: null, // kuota pertahun
    is_need_requirement: true,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
  {
    id: 7,
    nama: "Cuti Kematian",
    terpakai: 1,
    kuota: null, // kuota pertahun
    is_need_requirement: true,
    keterangan:
      "Keterangan entitas cuti, berupa deskripsi cuti, diperuntukan untuk apa, dan dapat disertakan syarat - syarat cuti disini, dapat diedit ",
  },
];

const dummyKuisioner = [
  {
    id: 1,
    pertanyaan:
      "Apakah Anda setuju bahwa rendang adalah makanan terbaik di dunia?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 2,
    pertanyaan: "Apakah menurut Anda cuaca hujan sangat menyenangkan?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 3,
    pertanyaan:
      "Apakah Anda setuju bahwa musim panas adalah waktu terbaik untuk liburan?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 4,
    pertanyaan:
      "Bagaimana pendapat Anda tentang teknologi dalam kehidupan sehari-hari: tidak setuju, netral, atau setuju?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 5,
    pertanyaan:
      "Apakah menurut Anda komedi lebih baik daripada drama dalam film atau pertunjukan TV?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 6,
    pertanyaan:
      "Apakah Anda setuju bahwa musik dapat mempengaruhi suasana hati seseorang secara signifikan?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 7,
    pertanyaan:
      "Bagaimana pandangan Anda tentang olahraga ekstrem: tidak setuju, netral, atau setuju?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 8,
    pertanyaan:
      "Apakah menurut Anda perjalanan waktu mungkin terjadi di masa depan? Tidak setuju, netral, atau setuju?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 9,
    pertanyaan:
      "Bagaimana pendapat Anda tentang kehidupan di luar Bumi: tidak setuju, netral, atau setuju?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
  {
    id: 10,
    pertanyaan:
      "Apakah menurut Anda menonton film di bioskop lebih baik daripada menonton di rumah? Tidak setuju, netral, atau setuju?",
    jabatan_id: null,
    deleted_at: null,
    created_at: "2024-07-12",
    updated_at: "2024-07-12",
  },
];

const dummyPengumuman = [
  {
    id: 1,
    judul: "Pengumuman Libur Nasional",
    konten: `Dalam rangka memperingati hari libur nasional, kantor akan tutup pada tanggal 17 Agustus. Hari libur ini diperingati sebagai Hari Kemerdekaan Indonesia dan merupakan hari yang sangat penting bagi bangsa kita. Semua karyawan diharapkan untuk mengibarkan bendera merah putih di rumah masing-masing dan mengikuti upacara peringatan secara virtual atau di lokasi yang telah ditentukan oleh pemerintah setempat.
    
    Selain itu, kami juga menganjurkan para karyawan untuk menggunakan hari libur ini sebagai waktu untuk beristirahat dan menghabiskan waktu bersama keluarga. Liburan ini adalah kesempatan yang baik untuk merayakan pencapaian negara kita dan juga untuk merenungkan sejarah perjuangan kemerdekaan.

    Kantor akan kembali beroperasi normal pada hari berikutnya. Harap memastikan semua pekerjaan penting diselesaikan sebelum tanggal 17 Agustus. Terima kasih atas perhatian dan kerja sama Anda. Selamat merayakan Hari Kemerdekaan!`,
    tgl_berakhir: "2024-07-31",
    created_at: new Date("2024-07-01T10:00:00"),
    updated_at: new Date("2024-07-01T10:00:00"),
  },
  {
    id: 2,
    judul: "Peningkatan Sistem",
    konten: `Akan ada pemeliharaan sistem pada tanggal 20 Juli 2024. Sistem akan tidak dapat diakses dari pukul 00:00 hingga 04:00. Pemeliharaan ini dilakukan untuk meningkatkan performa dan keamanan sistem kita agar dapat melayani Anda dengan lebih baik. Kami menyadari bahwa downtime ini mungkin akan sedikit mengganggu aktivitas Anda, namun kami telah memilih waktu yang paling minim dampaknya bagi sebagian besar karyawan.

    Selama waktu pemeliharaan, tim IT kami akan bekerja keras untuk memastikan bahwa semua proses peningkatan berjalan dengan lancar. Kami juga akan menguji sistem secara menyeluruh sebelum dan setelah pemeliharaan untuk memastikan tidak ada gangguan lebih lanjut.

    Jika Anda memiliki pekerjaan penting yang membutuhkan akses sistem, harap menyelesaikannya sebelum waktu pemeliharaan dimulai. Kami mohon maaf atas ketidaknyamanan yang mungkin ditimbulkan dan berterima kasih atas pengertian serta kerja samanya.`,
    tgl_berakhir: "2024-07-31",
    created_at: new Date("2024-07-05T14:30:00"),
    updated_at: new Date("2024-07-05T14:30:00"),
  },
  {
    id: 3,
    judul: "Pelatihan Karyawan Baru",
    konten: `Pelatihan karyawan baru akan dilaksanakan pada tanggal 25 Juli 2024 di ruang konferensi lantai 3. Pelatihan ini dirancang untuk membantu karyawan baru memahami budaya perusahaan, prosedur kerja, dan tanggung jawab mereka. Kami sangat menekankan pentingnya pelatihan ini karena ini adalah langkah pertama dalam perjalanan karir Anda di perusahaan kami.

    Sesi pelatihan akan mencakup berbagai topik, termasuk kebijakan perusahaan, keterampilan teknis yang relevan dengan posisi Anda, serta sesi tanya jawab untuk mengklarifikasi semua pertanyaan yang mungkin Anda miliki. Pelatihan ini juga akan menjadi kesempatan bagi Anda untuk bertemu dengan rekan-rekan kerja dan membangun jaringan profesional dalam perusahaan.

    Kami mengharapkan semua karyawan baru untuk hadir tepat waktu dan berpartisipasi aktif dalam setiap sesi. Kami percaya bahwa pelatihan ini akan memberikan dasar yang kuat bagi kesuksesan Anda di masa depan. Harap membawa catatan dan alat tulis untuk mencatat informasi penting selama pelatihan.`,
    tgl_berakhir: "2024-07-31",
    created_at: new Date("2024-07-10T09:15:00"),
    updated_at: new Date("2024-07-10T09:15:00"),
  },
  {
    id: 4,
    judul: "Pembagian Bonus Tahunan",
    konten: `Bonus tahunan akan dibagikan kepada seluruh karyawan pada akhir bulan Juli. Harap memastikan informasi rekening bank Anda sudah benar dan terverifikasi di sistem HRD kami. Bonus tahunan ini adalah bentuk apresiasi perusahaan atas kerja keras dan dedikasi Anda sepanjang tahun.

    Proses pembagian bonus akan dilakukan secara otomatis ke rekening bank yang telah terdaftar. Pastikan bahwa tidak ada perubahan informasi bank yang belum dilaporkan agar tidak terjadi keterlambatan dalam penerimaan bonus. Jika ada perubahan, segera laporkan ke departemen HRD sebelum tanggal 25 Juli.

    Kami berharap bonus ini dapat menjadi motivasi tambahan bagi Anda semua untuk terus memberikan yang terbaik bagi perusahaan. Terima kasih atas kerja keras dan kontribusi Anda. Semoga bonus ini dapat bermanfaat bagi Anda dan keluarga.`,
    tgl_berakhir: "2024-07-31",
    created_at: new Date("2024-07-12T11:00:00"),
    updated_at: new Date("2024-07-12T11:00:00"),
  },
  {
    id: 5,
    judul: "Sosialisasi Kesehatan Kerja",
    konten: `Akan diadakan sosialisasi tentang kesehatan kerja pada tanggal 30 Juli 2024. Seluruh karyawan diharapkan hadir karena acara ini sangat penting untuk meningkatkan kesadaran tentang kesehatan dan keselamatan kerja. Sosialisasi ini akan mencakup berbagai topik, termasuk ergonomi, pencegahan cedera, dan kebijakan kesehatan di tempat kerja.

    Sosialisasi ini akan disampaikan oleh ahli kesehatan kerja yang berpengalaman dan akan ada sesi interaktif di mana karyawan dapat mengajukan pertanyaan dan berbagi pengalaman. Kami percaya bahwa lingkungan kerja yang sehat dan aman adalah kunci untuk produktivitas yang tinggi dan kesejahteraan karyawan.

    Kami sangat mendorong partisipasi aktif dari semua karyawan dalam sosialisasi ini. Harap datang tepat waktu dan siapkan pertanyaan atau isu yang ingin dibahas. Kami berharap acara ini dapat memberikan pengetahuan dan keterampilan yang berguna untuk menjaga kesehatan dan keselamatan Anda di tempat kerja.`,
    tgl_berakhir: "2024-07-31",
    created_at: new Date("2024-07-13T08:00:00"),
    updated_at: new Date("2024-07-13T08:00:00"),
  },
];

export {
  dummyActivity,
  dummyCutis,
  dummyDataBerkas,
  dummyDetailKaryawan,
  dummyDokumens,
  dummyEventDiklats,
  dummyKaryawans,
  dummyKuisioner,
  dummyLemburs,
  dummyMySchedules,
  dummyPengajuanTukarJadwals,
  dummyPengumuman,
  dummyPermintaanTukarJadwals,
  dummySlipGaji,
  dummyTipeCutis,
};
